function Dev() {
    return (
        <svg id="dev" width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.15" fill-rule="evenodd" clip-rule="evenodd" d="M3 1C1.89543 1 1 1.89543 1 3V28C1 29.1046 1.89543 30 3 30H37C38.1046 30 39 29.1046 39 28V3C39 1.89543 38.1046 1 37 1H3ZM36 9H4V28H36V9Z" fill="url(#paint0_linear)" />
            <path d="M37.6172 0.375H2.38281C1.06903 0.375 0 1.44403 0 2.75781V29.2422C0 30.556 1.06903 31.625 2.38281 31.625H37.6172C38.931 31.625 40 30.556 40 29.2422V2.75781C40 1.44403 38.931 0.375 37.6172 0.375ZM38.4375 29.2422C38.4375 29.6945 38.0695 30.0625 37.6172 30.0625H2.38281C1.93054 30.0625 1.5625 29.6945 1.5625 29.2422V2.75781C1.5625 2.30554 1.93054 1.9375 2.38281 1.9375H37.6172C38.0695 1.9375 38.4375 2.30554 38.4375 2.75781V29.2422Z" fill="url(#paint1_linear)" />
            <path d="M14.9219 5.79156H35.0781C35.5096 5.79156 35.8594 5.44183 35.8594 5.01031C35.8594 4.5791 35.5096 4.22906 35.0781 4.22906H14.9219C14.4904 4.22906 14.1406 4.5791 14.1406 5.01031C14.1406 5.44183 14.4904 5.79156 14.9219 5.79156Z" fill="url(#paint2_linear)" />
            <path d="M3.99231 5.3094C4.01184 5.3564 4.03595 5.40157 4.06403 5.44368C4.0921 5.48671 4.12506 5.52669 4.16107 5.56239C4.30634 5.70857 4.50775 5.79158 4.71313 5.79158C4.76471 5.79158 4.81567 5.7867 4.86633 5.77663C4.91638 5.76656 4.96552 5.7516 5.01251 5.73207C5.05951 5.71254 5.10468 5.68843 5.14679 5.66035C5.18982 5.63136 5.2298 5.5984 5.2655 5.5627C5.30243 5.52669 5.33447 5.48671 5.36316 5.44398C5.39154 5.40157 5.41565 5.3564 5.43518 5.3094C5.45471 5.2618 5.46967 5.21266 5.47974 5.16322C5.48981 5.11256 5.49438 5.06099 5.49438 5.01033C5.49438 4.95937 5.48981 4.90779 5.47974 4.85805C5.46967 4.808 5.45471 4.75856 5.43518 4.71187C5.41565 4.66426 5.39154 4.61879 5.36316 4.57668C5.33447 4.53365 5.30243 4.49397 5.2655 4.45796C5.2298 4.42195 5.18982 4.3893 5.14679 4.36092C5.10468 4.33284 5.05951 4.30873 5.01251 4.2892C4.96552 4.26967 4.91638 4.25471 4.86633 4.24464C4.76563 4.2242 4.66187 4.2242 4.56085 4.24464C4.5108 4.25471 4.46167 4.26967 4.41498 4.2892C4.36707 4.30873 4.3219 4.33284 4.27979 4.36092C4.23676 4.3893 4.19678 4.42195 4.16107 4.45796C4.12506 4.49397 4.0921 4.53365 4.06403 4.57668C4.03595 4.61879 4.01184 4.66426 3.99231 4.71187C3.97278 4.75856 3.95782 4.808 3.94775 4.85805C3.93738 4.90779 3.93188 4.95937 3.93188 5.01033C3.93188 5.06099 3.93738 5.11256 3.94775 5.16322C3.95782 5.21266 3.97278 5.2618 3.99231 5.3094Z" fill="url(#paint3_linear)" />
            <path d="M7.11731 5.3094C7.13684 5.3564 7.16095 5.40157 7.18903 5.44368C7.2171 5.48671 7.25006 5.52669 7.28607 5.56239C7.43134 5.70857 7.63275 5.79158 7.83813 5.79158C7.88971 5.79158 7.94067 5.7867 7.99133 5.77663C8.04138 5.76656 8.09052 5.7516 8.13751 5.73207C8.18451 5.71254 8.22968 5.68843 8.27179 5.66035C8.31482 5.63136 8.3548 5.5984 8.39142 5.5627C8.42743 5.52669 8.45947 5.48671 8.48816 5.44398C8.51654 5.40157 8.54065 5.3564 8.56018 5.3094C8.57971 5.2618 8.59467 5.21266 8.60474 5.16322C8.61481 5.11256 8.61938 5.06099 8.61938 5.01033C8.61938 4.95937 8.61481 4.90779 8.60474 4.85805C8.59467 4.808 8.57971 4.75856 8.56018 4.71187C8.54065 4.66426 8.51654 4.61879 8.48816 4.57668C8.45947 4.53365 8.42743 4.49397 8.39142 4.45796C8.3548 4.42195 8.31482 4.3893 8.27179 4.36092C8.22968 4.33284 8.18451 4.30873 8.13751 4.2892C8.09052 4.26967 8.04138 4.25471 7.99133 4.24464C7.89063 4.2242 7.78687 4.2242 7.68585 4.24464C7.6358 4.25471 7.58667 4.26967 7.53998 4.2892C7.49207 4.30873 7.4469 4.33284 7.40479 4.36092C7.36176 4.3893 7.32178 4.42195 7.28607 4.45796C7.25006 4.49397 7.2171 4.53365 7.18903 4.57668C7.16095 4.61879 7.13684 4.66426 7.11731 4.71187C7.09778 4.75856 7.08282 4.808 7.07275 4.85805C7.06238 4.90779 7.05688 4.95937 7.05688 5.01033C7.05688 5.06099 7.06238 5.11256 7.07275 5.16322C7.08282 5.21266 7.09778 5.2618 7.11731 5.3094Z" fill="url(#paint4_linear)" />
            <path d="M10.2423 5.3094C10.2618 5.3564 10.2859 5.40157 10.314 5.44368C10.3421 5.48671 10.3751 5.52669 10.4111 5.56239C10.4468 5.5984 10.4868 5.63136 10.5298 5.66005C10.5719 5.68843 10.6171 5.71254 10.665 5.73207C10.7117 5.7516 10.7608 5.76656 10.8109 5.77663C10.8609 5.7867 10.9125 5.79128 10.9631 5.79128C11.0147 5.79128 11.0657 5.7867 11.1163 5.77663C11.1664 5.76656 11.2155 5.7516 11.2625 5.73207C11.3095 5.71254 11.3547 5.68843 11.3977 5.66005C11.4398 5.63136 11.4798 5.5984 11.5164 5.56239C11.5524 5.52669 11.5845 5.48671 11.6132 5.44368C11.6415 5.40157 11.6656 5.3564 11.6852 5.3094C11.7047 5.2618 11.7197 5.21266 11.7297 5.16322C11.7398 5.11256 11.7444 5.06099 11.7444 5.01003C11.7444 4.95937 11.7398 4.90779 11.7297 4.85774C11.7197 4.80769 11.7047 4.75856 11.6852 4.71187C11.6656 4.66396 11.6415 4.61879 11.6132 4.57668C11.5845 4.53365 11.5524 4.49367 11.5164 4.45796C11.4798 4.42195 11.4398 4.38899 11.3977 4.36092C11.3547 4.33284 11.3095 4.30873 11.2625 4.2892C11.2155 4.26967 11.1664 4.25471 11.1163 4.24464C11.0156 4.2242 10.9119 4.2242 10.8109 4.24464C10.7608 4.25471 10.7117 4.26967 10.665 4.2892C10.6171 4.30873 10.5719 4.33284 10.5298 4.36092C10.4868 4.38899 10.4468 4.42195 10.4111 4.45796C10.3751 4.49367 10.3421 4.53365 10.314 4.57668C10.2859 4.61879 10.2618 4.66396 10.2423 4.71187C10.2228 4.75856 10.2078 4.80769 10.1978 4.85774C10.1874 4.90779 10.1819 4.95937 10.1819 5.01003C10.1819 5.06099 10.1874 5.11256 10.1978 5.16322C10.2078 5.21266 10.2228 5.2618 10.2423 5.3094Z" fill="url(#paint5_linear)" />
            <path d="M22.7154 13.739C22.344 13.5192 21.8649 13.6425 21.6452 14.0139L17.0099 21.8524C16.7901 22.2238 16.9131 22.7029 17.2845 22.9223C17.4093 22.9962 17.5464 23.0316 17.6816 23.0316C17.9486 23.0316 18.2089 22.8942 18.3548 22.6477L22.9901 14.8092C23.2098 14.4378 23.0868 13.9587 22.7154 13.739Z" fill="url(#paint6_linear)" />
            <path d="M12.9769 16.0351V15.2462C12.9769 14.8095 13.3321 14.454 13.7688 14.454H13.7798C14.2113 14.454 14.561 14.1042 14.561 13.6727C14.561 13.2415 14.2113 12.8915 13.7798 12.8915H13.7688C12.4706 12.8915 11.4144 13.948 11.4144 15.2462V16.0351C11.4144 16.3192 11.2978 16.5963 11.0946 16.795L10.0823 17.7853C9.93305 17.9312 9.84852 18.1307 9.8473 18.3395C9.84638 18.5482 9.92878 18.7487 10.0765 18.8964L11.108 19.9276C11.307 20.1263 11.4174 20.3906 11.4196 20.6719L11.4251 21.4907C11.4342 22.7801 12.4904 23.8293 13.7798 23.8293C14.2113 23.8293 14.561 23.4792 14.561 23.048C14.561 22.6165 14.2113 22.2668 13.7798 22.2668C13.3458 22.2668 12.9906 21.9137 12.9876 21.48L12.9821 20.6613C12.9772 19.9664 12.7037 19.3133 12.2127 18.8226L11.7397 18.3499L12.1874 17.9119C12.6891 17.4209 12.9769 16.737 12.9769 16.0351Z" fill="url(#paint7_linear)" />
            <path d="M27.023 20.6857V21.4745C27.023 21.9113 26.6678 22.2668 26.2311 22.2668H26.2201C25.7886 22.2668 25.4388 22.6165 25.4388 23.048C25.4388 23.4792 25.7886 23.8293 26.2201 23.8293H26.2311C27.5293 23.8293 28.5855 22.7728 28.5855 21.4745V20.6857C28.5855 20.4016 28.7021 20.1245 28.9053 19.9258L29.9176 18.9355C30.0668 18.7896 30.1514 18.59 30.1523 18.3813C30.1535 18.1725 30.0711 17.972 29.9234 17.8243L28.8919 16.7932C28.6929 16.5945 28.5821 16.3299 28.5803 16.0488L28.5745 15.23C28.5657 13.9407 27.5095 12.8918 26.2201 12.8918C25.7886 12.8918 25.4388 13.2415 25.4388 13.673C25.4388 14.1042 25.7886 14.4543 26.2201 14.4543C26.6537 14.4543 27.0093 14.8071 27.012 15.2407L27.0178 16.0595C27.0227 16.7541 27.2958 17.4072 27.7872 17.8982L28.2602 18.3709L27.8125 18.8088C27.3108 19.2996 27.023 19.9838 27.023 20.6857Z" fill="url(#paint8_linear)" />
            <path d="M36.0443 8.13531H3.95569C3.52417 8.13531 3.17444 8.48535 3.17444 8.91656V27.6666C3.17444 28.0981 3.52417 28.4478 3.95569 28.4478H13.2553C13.6868 28.4478 14.0366 28.0981 14.0366 27.6666C14.0366 27.2354 13.6868 26.8853 13.2553 26.8853H4.73694V9.69781H35.2631V26.8853H19.5312C19.0997 26.8853 18.75 27.2354 18.75 27.6666C18.75 28.0981 19.0997 28.4478 19.5312 28.4478H36.0443C36.4758 28.4478 36.8256 28.0981 36.8256 27.6666V8.91656C36.8256 8.48535 36.4758 8.13531 36.0443 8.13531Z" fill="url(#paint9_linear)" />
            <defs>
                <linearGradient id="paint0_linear" x1="3.15333" y1="1" x2="26.6886" y2="35.0542" gradientUnits="userSpaceOnUse">
                    <stop offset="0.259336" className="icon-gradient-0" />
                    <stop offset="1" className="icon-gradient-1" />
                </linearGradient>
                <linearGradient id="paint1_linear" x1="2.26667" y1="0.375001" x2="27.8327" y2="36.511" gradientUnits="userSpaceOnUse">
                    <stop offset="0.259336" className="icon-gradient-0" />
                    <stop offset="1" className="icon-gradient-1" />
                </linearGradient>
                <linearGradient id="paint2_linear" x1="15.3714" y1="4.22906" x2="15.5472" y2="6.9288" gradientUnits="userSpaceOnUse">
                    <stop offset="0.259336" className="icon-gradient-0" />
                    <stop offset="1" className="icon-gradient-1" />
                </linearGradient>
                <linearGradient id="paint3_linear" x1="4.02043" y1="4.22931" x2="5.36917" y2="5.71887" gradientUnits="userSpaceOnUse">
                    <stop offset="0.259336" className="icon-gradient-0" />
                    <stop offset="1" className="icon-gradient-1" />
                </linearGradient>
                <linearGradient id="paint4_linear" x1="7.14543" y1="4.22931" x2="8.49417" y2="5.71887" gradientUnits="userSpaceOnUse">
                    <stop offset="0.259336" className="icon-gradient-0" />
                    <stop offset="1" className="icon-gradient-1" />
                </linearGradient>
                <linearGradient id="paint5_linear" x1="10.2704" y1="4.22931" x2="11.6189" y2="5.71884" gradientUnits="userSpaceOnUse">
                    <stop offset="0.259336" className="icon-gradient-0" />
                    <stop offset="1" className="icon-gradient-1" />
                </linearGradient>
                <linearGradient id="paint6_linear" x1="17.2521" y1="13.6301" x2="25.0143" y2="19.281" gradientUnits="userSpaceOnUse">
                    <stop offset="0.259336" className="icon-gradient-0" />
                    <stop offset="1" className="icon-gradient-1" />
                </linearGradient>
                <linearGradient id="paint7_linear" x1="10.1144" y1="12.8915" x2="17.4785" y2="16.3959" gradientUnits="userSpaceOnUse">
                    <stop offset="0.259336" className="icon-gradient-0" />
                    <stop offset="1" className="icon-gradient-1" />
                </linearGradient>
                <linearGradient id="paint8_linear" x1="25.7059" y1="12.8918" x2="33.0696" y2="16.3959" gradientUnits="userSpaceOnUse">
                    <stop offset="0.259336" className="icon-gradient-0" />
                    <stop offset="1" className="icon-gradient-1" />
                </linearGradient>
                <linearGradient id="paint9_linear" x1="5.08133" y1="8.13532" x2="19.9152" y2="35.2721" gradientUnits="userSpaceOnUse">
                    <stop offset="0.259336" className="icon-gradient-0" />
                    <stop offset="1" className="icon-gradient-1" />
                </linearGradient>
            </defs>
        </svg>
    )
}
export default Dev;